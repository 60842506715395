import {FeaturedProject, ProjectItem} from "../Timeline/VerticalTimeline";
import {ImageGallery} from "./ProjectCard";
import {APP} from "../../util/constants";
import {CloseIcon} from "../Icons/Icon";
import React from "react";

export interface DemoSlide {
    image: string;
    caption: string;
}

export const socialImages = [
    'demo-apps-screenshots/social-media-web-app/screenshot-1',
    'demo-apps-screenshots/social-media-web-app/screenshot-2',
    'demo-apps-screenshots/social-media-web-app/screenshot-3',
    'demo-apps-screenshots/social-media-web-app/screenshot-4',
    'demo-apps-screenshots/social-media-web-app/screenshot-5',
    'demo-apps-screenshots/social-media-web-app/screenshot-6',
    'demo-apps-screenshots/social-media-web-app/screenshot-7',
    'demo-apps-screenshots/social-media-web-app/screenshot-8',
    'demo-apps-screenshots/social-media-web-app/screenshot-9',
    'demo-apps-screenshots/social-media-web-app/screenshot-10',
];
export const socialCaptions = [
    'Login screen',
    'Login screen',
    'Login screen',
    'Login screen',
    'Login screen',
    'Login screen',
    'Login screen',
    'Login screen',
    'Login screen',
    'Login screen',
];
const makeDemoSlides = (slideCount: number, images: string[], captions: []) => {
    let demoSlides: DemoSlide[] = [];
    for (let i = 0; i < slideCount; i++) {
        const slide = {
            image: images[slideCount],
            caption: captions?.[slideCount] ?? 'No caption'
        }
        demoSlides.concat(slide);
    }
    return demoSlides;
}
export const images2 = [
    'demo-apps-screenshots/property-booking-web-app/screenshot-1',
    'demo-apps-screenshots/property-booking-web-app/screenshot-2',
    'demo-apps-screenshots/property-booking-web-app/screenshot-3',
    'demo-apps-screenshots/property-booking-web-app/screenshot-4',
    'demo-apps-screenshots/property-booking-web-app/screenshot-5',
    'demo-apps-screenshots/property-booking-web-app/screenshot-6',
];

interface FeaturedProjectProps {
    project: FeaturedProject;
    classNames?: string;
    isInitiated?: boolean;
}
export function FeaturedProjectCard(props: FeaturedProjectProps) {
    const {
        project,
        classNames,
        isInitiated = true
    } = props;
    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    /*React.useEffect(() => {
        if (!props.isInitiated) {
            setActiveIndex(0);
        }
    }, [props.isInitiated, activeIndex]);*/
    return(
        <div className={`Featured-project-card ${classNames}`}>
            <header className={'Mobile-header'}>
                <h2 className={'Type-subtitle large'}>{project.name}</h2>
            </header>
            <div className={'details'}>
                <header>
                    <h2 className={'Type-subtitle large'}>{project.name}</h2>
                </header>
                <div>{project.descriptions?.map((d, i) =>
                    <div onClick={() => setActiveIndex(i)}
                         style={{
                             cursor: 'pointer',
                             marginBottom: '1rem',
                             color: activeIndex === i ? 'blue' : '#2f2f2f'
                         }}>{d}</div>
                )}</div>
            </div>
            <div className={'gallery'}>
                <ImageGallery activeIndex={activeIndex} setActiveIndex={setActiveIndex}
                              srcList={project.images.map(image => `${APP.CDN}/${image}`)}/>
                <div className={'Tech-logos'}>{project.techLogos?.map(techLogo => {
                    return (
                        <span className={'Tech-logo'} key={techLogo.key}>
                            {techLogo}
                        </span>
                    );
                })}</div>
            </div>
            <div className={'Tech-logos mobile'}>{project.techLogos?.map(techLogo => {
                return (
                    <span className={'Tech-logo'} key={`${techLogo}`}>
                            {techLogo}
                        </span>
                );
            })}</div>
            <div className={'mobile'}>
                <span>{project.descriptions?.[activeIndex]}</span>
            </div>

        </div>
    );
}

interface FeaturedProjectLightboxProps {
    project: FeaturedProject;
    classNames?: string;
    closeModal?: () => void;
}

export function FeaturedProjectLightbox(props: FeaturedProjectLightboxProps) {
    const {
        project,
        classNames,
        closeModal
    } = props;
    //const {closeModal} = useModal();
    const [activeIndex, setActiveIndex] = React.useState<number>(0);

    return(
        <div className={`Featured-project-lightbox`}>
            <div className={'details'}>
                <header>
                    <h2 className={'Type-heading large'}>{project.name}</h2>
                </header>
            </div>
            <div className={'gallery'}>
                <ImageGallery activeIndex={activeIndex} setActiveIndex={setActiveIndex} srcList={project.images.map(image => `${APP.CDN}/${image}`)}/>
            </div>
            <span className={'Close-button-container'}>
                <button className={'Close-button fill-size'} onClick={closeModal}>
                    <CloseIcon/>
                </button>
            </span>
        </div>
    );
}

