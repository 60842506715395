import React, {Context, ReactNode} from "react";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {createPortal} from "react-dom";
import {useScrollLock} from "../../hooks/useScrollLock";

export const ModalContext: Context<any> = React.createContext({});
interface ModalContextProviderProps {
    children: ReactNode;
}
export function ModalContextProvider(props: ModalContextProviderProps) {
    const {
        children
    } = props;
    const [scrollOffset, setScrollOffset] = React.useState<number>(0);
    const [isModalActive, setIsModalActive] = React.useState<boolean>(false);
    const [modalContent, setModalContent] = React.useState<ReactNode>(null);

   // const [modalOptions, setModalOptions] = React.useState<IModalOptions>(defaultModalOptions);
    return(
        <ModalContext.Provider value={{isModalActive, setIsModalActive, modalContent, setModalContent, scrollOffset, setScrollOffset}}>
            <ModalManager/>
            {children}
        </ModalContext.Provider>
    );
}
interface ModalManagerProps {
    children?: ReactNode;
}
export function ModalManager(props: ModalManagerProps) {
    const {
        children
    } = props;
    const {isModalActive, modalContent, openModal, closeModal, modalContainerRef, setIsModalActive, setModalContent, modalOptions} = useModal();
    useOutsideClick(modalContainerRef, (ev) => {
        if(isModalActive && !modalOptions.portal) {
            console.log('click outside');
            console.log(modalContainerRef);
            closeModal();
        }
    });
    //const Backdrop = modalOptions.scrim ? <Scrim/>
    const PortalModal = (createPortal(<div ref={modalContainerRef} id={'portal-spawn'} style={{zIndex: 20000, width: '100%', height: '100vh', position: 'fixed'}}>{modalContent}</div>, document.getElementById('root') as Element));

    return isModalActive ? PortalModal : null;
}
export function useModal() {
    const {scrollOffset, setScrollOffset, isModalActive, setIsModalActive, modalContent, setModalContent, modalOptions, setModalOptions} = React.useContext(ModalContext);
    const modalContainerRef = React.useRef(null);
    const scrollPos = React.useRef(0);
    const {lockScrolling, unlockScrolling} = useScrollLock();
    function openModal(content?: ReactNode, pos?: number) {
        setScrollOffset(Math.abs(pos || 0));
        scrollPos.current = pos || 0;
        const offset = pos || 0;
        console.log(scrollOffset);
        console.log(scrollPos.current);
        console.log(offset);
        console.log(pos);
        lockScrolling(pos);
        setModalContent(content);
        setIsModalActive(true);
    }
    function closeModal() {
        //unlockScrolling(scrollOffset);
        unlockScrolling(scrollPos.current);
        setIsModalActive(false);
    }
    function modalIsOpen(): boolean {
        return isModalActive;
    }
    return {scrollOffset, setScrollOffset, isModalActive, modalContent, openModal, closeModal, modalContainerRef, setIsModalActive, setModalContent, modalOptions, setModalOptions};
}
