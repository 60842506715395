export enum Months {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}
export enum MonthsUTC {
    January = 1,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}

const monthUTC = 7;

const month = {MonthsUTC}.MonthsUTC[monthUTC];
//console.log(month);

interface IDate {
    year?: number;
    month?: number;
    day?: number;
    dayOfWeek?: string;
}

type formatDateConfigType = {
    year?: boolean;
    monthNumeric?: boolean;
    monthAlpha?: boolean;
    dayNumeric?: boolean;
    dayOfWeek?: boolean;
};
const DateFormatTemplate = '{month} [yyyy]';
export function formatDate(date: Date, options?: formatDateConfigType) {
    const year = date.getFullYear();
    //console.log(year);
    const yearUTC = date.getUTCFullYear();
   // console.log(yearUTC);
    const monthStringUTC = MonthsUTC[date.getUTCMonth()];
    const monthString = monthStringUTC.length > 4 ? monthStringUTC.substring(0, 3).concat('.') : monthStringUTC;
   // console.log(monthStringUTC);
    //const formatted = `${monthStringUTC} ${yearUTC}`;
    const formatted = `${monthString} ${yearUTC}`;
    return formatted;
}
