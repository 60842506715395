import React, {ForwardedRef, ReactNode} from "react";

export const AvatarUrl = 'https://d2969si02yezyk.cloudfront.net/3e10385a-0514-4416-8114-43ae9b5756b6-IMG_8375.jpg';

interface AvatarProps {
    src: string;
    alt?: string;
}
export const Avatar = React.forwardRef((props: AvatarProps, ref: ForwardedRef<HTMLSpanElement>) => {
    const {
        src = AvatarUrl,
        alt = 'avatar'
    } = props;
    return(
        <>
        <span ref={ref} className={'Big-avatar-container'}>
            <img style={{maxWidth: '100%', objectFit: 'cover', filter: 'grayscale(1)'}} src={src} alt={alt}/>
            <span className={'Avatar-effect effect-1'}/>
            <span className={'Avatar-effect effect-2'}/>
        </span>
        </>
    );
});

interface Props {
    children?: ReactNode;
}
export function TransformControlWrapper(props: Props) {
    const {
        children
    } = props;
    const measurementRef = React.useRef<HTMLSpanElement>(null);
    /*const marker = React.useRef(<span/>);
    React.useLayoutEffect(() => {
        if(measurementRef.current) {
            const rect = measurementRef.current.getBoundingClientRect();

            const markTL = document.createElement('span');
            markTL.style.backgroundColor = 'aquamarine';
            markTL.style.width = '1rem';
            markTL.style.height = '1rem';
            markTL.style.position = 'absolute';
            markTL.style.top = '-3rem';
            markTL.style.left = '-3rem';

            const markBL = document.createElement('span');
            markBL.style.backgroundColor = 'aquamarine';
            markBL.style.width = '1rem';
            markBL.style.height = '1rem';
            markBL.style.position = 'absolute';
            markBL.style.top = `calc(${rect.height}px - 2rem )`;
            markBL.style.left = '-3rem';

            measurementRef.current.appendChild(markTL);
            measurementRef.current.appendChild(markBL);
        }
    }, []);*/
    return(
        <div style={{position: 'relative', display: 'block'}} >
            <Avatar src={AvatarUrl} ref={measurementRef}/>
        </div>
    );
}
