import {IPosition} from "./useScrollPosition";

export enum EventTypes {
    Click,
    ScrollStart,
    ScrollEnd,
    Hover
}
export enum AppActionTypes {
    ClickLink,
    OpenModal,
    CloseModal,
}
export const makeAction = (type: string) => {

}
interface TrackingProps {}
export function useTracking() {

    function track() {

    }
    const trackAction = (name: string, data: unknown = {}) => {
        
    }

    const trackScroll = (duration: number) => {

    }

    const trackScrollStream = (previousPosition: IPosition, currentPosition: IPosition, timeStart: number, timeEnd: number) => {
        const duration = timeEnd - timeStart;
        console.log(`Duration: ${duration}`);
    }

    const trackContentLoaded = (content: string) => {
        console.log(`Loaded content: ${content}`);
    }

    return {trackAction, trackScroll, trackContentLoaded, trackScrollStream}
}
