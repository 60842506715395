import React, {RefObject} from "react";
import {useUpdateEffect} from "./useUpdateEffect";

export function useOutsideClick(ref: RefObject<HTMLElement>, callbackFn: (event: Event) => void) {
    function handleClick(event: MouseEvent): void {
        if(ref.current && !ref.current.contains(event.target as Node)) {
            callbackFn(event);
        }
    }
    /*React.useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [ref, callbackFn]);*/
    useUpdateEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [ref, callbackFn]);
}
