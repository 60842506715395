import React from "react";
import {formatDate} from "../../util/dateUtils";
import {FadeInText, ProjectItem, SvgIconReact, ViewportObserver, WorkItem} from "./VerticalTimeline";
import {useModal} from "../Modal/ModalManager";

interface ProjectTimelineItemProps {
    //id for identifying a specific timeline item
    id?: string;
    //id of the timeline that contains this item.
    groupId?: string;
    //insertion position of item in the timeline group
    index?: number;

    item: ProjectItem;
}
export function ProjectTimelineItem(props: ProjectTimelineItemProps) {
    const {
        id,
        groupId,
        index = -1,
        item
    } = props;
    const [isVisible, setIsVisible] = React.useState(true);
    const coloring = item.coloring || '#17eaec';
    const {openModal, closeModal} = useModal();
    return(
        <ViewportObserver>
            <div data-visible={isVisible} className={`Timeline-entry ${(index+2)%2===0?'':'reverse'}`}>
            <span style={{backgroundColor: coloring}} className={'Timeline-item_icon Timeline_marker'}>
                {item.markerLogo || <SvgIconReact/>}
            </span>
                <div data-visible={isVisible} className={'Timeline-item Card'}>
                    {/*<span className={`Timeline-item_arrow ${(index+2)%2===0?'':'reverse'}`}/>*/}
                    <span/>
                    <div style={{borderTopColor: coloring==='#ffffff'?'#f58219':coloring}} className={`Timeline-item_content project-timeline ${encodeURIComponent(item.name)} ${(index+2)%2===0?'':'reverse'}`}>
                        <header id={item.name.toLowerCase()} className={'ProjectTimelineItem-header'}>
                            <h2 className={'Type-title medium'}>{item.name}</h2>
                        </header>
                        <p className={'Type-body small'}>{item.description}</p>
                        <ul className={'WorkTimelineItem-list'}>
                            {item.descriptions && item.descriptions.map((listItem) => {
                                return(
                                    <li className={'WorkTimelineItem-list-item'}>
                                        <span className={'Type-body small'}>{listItem}</span>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className={`Timeline-item_date Timeline_project OneColumn`}>
                        <span className={'Type-label small'}>
                            {formatDate(new Date(item.dateRange.start))} - {item.dateRange.finish==='current' ? 'current' : formatDate(new Date(item.dateRange.finish))}
                        </span>
                        </div>
                        <div className={'WorkTimelineItem-logos'} style={{marginTop: '0rem'}}>{item.techLogos && item.techLogos.map((logo) => {
                            return logo;
                        })}</div>
                        {/*<div>{item.tags.map(tag => <span key={tag}>{tag}</span>)}</div>*/}
                        {/*<div className={'ProjectTimelineItem-logos'} style={{marginTop: '1rem'}}>{item.techLogos && item.techLogos.map((logo) => {
                            return logo;
                        })}</div>*/}
                        {/*<button onClick={() => openModal(<div><h1>MODAL</h1></div>)}>view pics</button>*/}
                    </div>
                    <div className={`Timeline-item_date TwoColumn ${(index+2)%2===0?'':'reverse'}`}>
                        <FadeInText>
                        <span className={'Type-label large'}>
                            {formatDate(new Date(item.dateRange.start))} - {item.dateRange.finish==='current' ? 'current' : formatDate(new Date(item.dateRange.finish))}
                        </span>
                        </FadeInText>
                    </div>
                </div>
            </div>
        </ViewportObserver>
    );
}
