import {Avatar, AvatarUrl, TransformControlWrapper} from "../Avatar/Avatar";
import {RotatingText} from "../Typography/RotatingText";
import React from "react";

interface ProfileCardProps {}
export function ProfileCard(props: ProfileCardProps) {
    const {} = props;
    return(
        <div className={'Profile-card'}>
            <div className={'Heading-container'}>
                <Avatar src={AvatarUrl}/>
                {/*<TransformControlWrapper/>*/}
                <div className={'Profile-card-text-container'}>
                    <div style={{fontFamily: 'var(--ff-0)'}} className={'Type-title large title'}>{`Steven Toben`}</div>
                    {/*<div className={'Type-subtitle small subtitle'}>{`Experienced Front-end and Back-end Software Engineer.`}</div>
                    <div className={'Type-subtitle small subtitle'}>{`A true Full-stack Software Engineer.`}</div>*/}
                    <div className={'Type-subtitle small subtitle'}>{`Experienced full-stack software engineer.`}</div>
                </div>
            </div>
            {/*<div>
                <div className={'Type-title small'}>{`About me`}</div>
                <div className={'Type-title small'}>{`About me`}</div>
            </div>*/}
            {/*<div>
                <Button variant={'fill'} coloring={'primary'} label={'View resume'}/>
            </div>
            <div>
                <span>{`steventoben@gmail.com`}</span>
                <span>{`+1 (636) 385-4630`}</span>
            </div>*/}
        </div>
    );
}
