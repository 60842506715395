import React, {ReactNode} from "react";

interface IconButtonProps {
    icon?: ReactNode;
    href?: string;
    linkLabel?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    size?: number;
}
export function IconButton(props: IconButtonProps) {
    const {
        icon,
        href,
        linkLabel,
        onClick,
        className = 'Icon-button',
        size = 1
    } = props;
    const component = href ? (<a style={{width: `${size}rem`, height: `${size}rem`}} aria-label={linkLabel} className={className} href={href}>{icon}</a>) : (<button aria-label={linkLabel} className={className} onClick={onClick}>{icon}</button>);
    return(
        component
    );
}
