import {start} from "node:repl";
import {SetStateAction} from "react";

interface ProgressIndicatorProps {
    totalCount: number;
    currentProgress: number;
    setActiveIndex: React.Dispatch<SetStateAction<number>>;
}

export function ProgressIndicator(props: ProgressIndicatorProps) {
    const {
        totalCount,
        currentProgress,
        setActiveIndex
    } = props;
    return (
        <div className="ProgressIndicator">
            {fillArrayTo(0, totalCount).map(value => {
                return(
                    <ProgressItem onClick={() => {setActiveIndex(value); console.log(`setting to ${value}`)}} isActive={value===currentProgress} key={value} />
                );
            })}
        </div>
    );
}

interface ProgressItemProps {
    isActive: boolean;
    onClick: Function;
}

function ProgressItem(props: ProgressItemProps) {
    const {
        isActive,
        onClick
    } = props;
    return (
        <button onClick={() =>{onClick(); console.log('clicked')}} className={`ProgressIndicatorItem ${isActive ? 'Active' : ''}`}></button>
    );
}

function fillArrayTo(start: number, count: number) {
    let array = [];
    for (let i = start; i < start + count; i++) {
        array.push(i);
    }
    return array;
}
