import React, {ReactNode} from "react";
import {makeClassNames} from "../../util/classn";

export type ThemeColorTypes = 'primary' | 'secondary' | 'accent' | 'neutral' | 'system';
type ButtonVariantTypes = 'fill' | 'outline' | 'inverse-outline' | 'elevated' | 'tonal' | 'ghost';

interface ButtonProps {
    id?: string;
    className?: string;
    disabled?: boolean;
    label?: string;
    variant?: ButtonVariantTypes;
    coloring?: ThemeColorTypes;
    icon?: ReactNode;
    iconPosition?: 'left' | 'right';
    sizing?: 'small' | 'medium' | 'large'; //theme defined control sizing i.e. padding and height
    block?: boolean;//block width/takes up 100% of container
    field?: boolean;//field height/takes up 100% of container
    type?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    action?: Function;
    onFocus?: React.FocusEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    chevron?: boolean;
    expanded?: boolean;
    href?: string;
}
export const Button = React.forwardRef(function Button(props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) {
    const {
        id,
        className,
        disabled,
        label,
        variant,
        coloring,
        icon,
        iconPosition,
        sizing = 'medium',
        block = false,
        field,
        type,
        onClick,
        onFocus,
        chevron,
        expanded,
        href,
        action
    } = props;
    const concatted = classes.call(null, 'Button', [coloring, disabled, variant, sizing, block, field]);
    const concat = classex.call(null, 'Button', {coloring, disabled, variant, sizing, block, field});
    //const classNames = makeClassNames(Button.name, {...props});
    return(
        href
            ?
            <a href={href} style={{position: 'relative'}} onFocus={onFocus} type={type} id={id} className={concat} >
                <span className={'Label Type-label Type-level_2 Type-label_button'}>{label}</span>
            </a>
            :
            <button style={{position: 'relative'}} onClick={onClick} onFocus={onFocus} type={type} id={id}
                    className={concat} /*className={concatted.concat(block ? ' block' : '')}*/>
                <span className={'Label Type-label Type-level_2 Type-label_button'}>{label}</span>
            </button>

    );
});


export function classes(base: string = '', conds: any[]) {
    let classnames: string = base;
   // console.log(base, conds);
    const conditions = conds.filter((value) => value!==undefined);
   // console.log(base, conditions);
    const names = (obj: object) => Object.keys(obj)[0];
    const testi = conditions[0];
   // console.log(`conditions variable: ${names({})}`);
    for (const check of conditions) {
      //  console.log(check + ' ' + typeof check + ' ');
      //  console.log(`condition variable: ${names({check})}`);
        if(typeof check === "string") {
            classnames = classnames.concat(' ', check);
        }
        if(typeof check === "boolean") {
            classnames = classnames.concat(' ', check ? `${check}` : '');
        }
    }
    return classnames;
}
type ClassType = {
    [name: string]: any;
};
export function classex(base: string = '', conds: ClassType) {
    let classnames: string = base;
   // console.log(base, conds);
    const conditions = new Map(Object.entries(conds));//conds.filter((value: any) => value!==undefined);
    conditions.forEach(((value, key, map) => {
        if(typeof value === "undefined") {
            map.delete(key);
        }
    }));
  //  console.log(base, conditions);
    const names = (obj: object) => Object.keys(obj)[0];
    //const testi = conditions[0];
    //console.log(`conditions variable: ${names({})}`);
    conditions.forEach(((value, key, map) => {
       // console.log(value + ' ' + typeof value + ' ');
       // console.log(`condition variable V: ${value}`);
       // console.log(`condition variable K: ${key}`);
        if(typeof value === "string") {
            classnames = classnames.concat(' ', value);
        }
        if(typeof value === "boolean") {
            classnames = classnames.concat(' ', value ? `${key}` : '');
        }
    }));
    return classnames;
}
