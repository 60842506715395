import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  GithubIcon,
  JavaLogo, LinkedInIcon,
  NodeJsLogo,
  PostgresLogo,
  ReactLogo,
  SpringFrameworkLogo,
  TypeScriptLogo
} from "./components/Icons/Icon";
import {TechnologyCard} from "./components/Card/Card";
import {
  featuredProject1, featuredProject2,
  projectItemSample,
  ProjectsTimeline,
  ViewportObserver,
  WorkTimeline
} from "./components/Timeline/VerticalTimeline";
import {ProfileCard} from "./components/ProfileCard/ProfileCard";
import {IconButton} from "./components/Button/IconButton";
import {Header} from "./components/Header/Header";
import {Button} from "./components/Button/Button";
import {Modal} from "./components/Modal/Modal";
import {useOutsideClick} from "./hooks/useOutsideClick";
import {FeaturedProjectCard, FeaturedProjectLightbox, images2} from "./components/ProjectCard/FeaturedProject";
import {useScrollPosition} from "./hooks/useScrollPosition";
import {useModal} from "./components/Modal/ModalManager";
import {RotatingText} from "./components/Typography/RotatingText";
import {useTracking} from "./hooks/useTracking";
import {musicPlayerProject, privateNoteProject, ProjectCard, timeBookingProject} from "./components/Card/ProjectCard";
import {Gallery} from "./components/ProjectCard/ProjectCard";
import {Swiper, SwiperSlide} from "swiper/react";


function App() {
  const {trackContentLoaded, trackScrollStream} = useTracking();
  const scrollPosition = React.useRef(0);
  const opacityRef = React.useRef<HTMLDivElement>(null);
  //let [ap, setAp] = React.useState(0);
  const [isInitiated, setIsInitiated] = React.useState({
    socialApp: false,
    bookingApp: false
  });
  const {openModal, closeModal, isModalActive} = useModal();

  useScrollPosition((props) => {
    scrollPosition.current = Math.abs(props.currPos.y);
    //setScrollOffset(Math.abs(props.currPos.y));
    //scrollPosition.current = Math.abs(props.prevPos.y);
    console.log(scrollPosition.current);
    console.log(props);
    //console.log(performance.now());
    console.log(performance.now());

    trackScrollStream(props.prevPos, props.currPos, props.prevTime, props.currTime);

  }, 500);
  const [isOpen, setIsOpen] = React.useState(false);
  const modalRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, () => {
    if(isOpen) {
      setIsOpen(false);
    }
  });
  /*function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }*/
  /*const closeModal = React.useCallback(() => {
    setIsOpen(false);
  }, []);*/
  function handleClick(ev: React.MouseEvent<HTMLButtonElement>) {
    //alert('hi');
    console.log(isOpen);
    setIsOpen(true);
  }
  return(
      <div className={'App'}>

        <div className={'Section-splash'}>
          <div className={'Splash-intro-container'}>
            <div className={'Splash-circle-mask'}>

            </div>
            <div>
              <div style={{color: 'white', fontWeight: '400'}} className={'Type-display small'}>Hi, I'm</div>
              <div style={{color: 'white'}} className={'Type-display small'}>Steven Toben</div>
            </div>
          </div>
        </div>

        {/*<div style={{height: '40rem'}}/>*/}

        <Header/>

        <div className={'Section-intro'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh'}}>
          <div className={'relative Intro-circle-mask'}>
            <ProfileCard/>
            {/*<div style={{height: '4rem'}}/>
            <RotatingText text={['Front-end', 'Back-end', 'Full-stack']} />*/}
            <div style={{height: '4rem'}}/>
            <div className={'Section-about About-paragraph'}>
              <h3 style={{marginBottom: '0.5rem'}} className={'Type-title small'}>{`About me`}</h3>
              <p className={'Type-body large'}>{`I'm a 24 year old software engineer based out of St. Louis, Missouri. I have a passion for learning and staying up to date with the latest technologies. My favorite thing about the software field is how it's ever-evolving and there's always new things for me to learn.`}</p>
            </div>
            <div style={{height: '4rem'}}/>
            <div className={'Button-group intro'}>
              <Button href={'https://d2969si02yezyk.cloudfront.net/StevenTobenResume2024.pdf'} sizing={'large'}
                      label={'View resume'} variant={'outline'} coloring={'neutral'}/>
              {/*<Button onClick={handleClick} sizing={'large'} label={'Get in touch'} variant={'fill'} coloring={'primary'}/>\
              <button onClick={() => setIsOpen(true)}>test</button>*/}
            </div>
            <div style={{height: '4rem'}}/>
            <div className={'Get-in-touch-container'}>
              <h4 className={'Type-title large title'}>{`Get in Touch`}</h4>
              <div className={'Social-icons'}>
                <IconButton size={2.5} icon={<GithubIcon/>}
                            linkLabel={'Github account of Steven Toben containing many software engineering projects'}
                            href={'https://github.com/steventoben'}/>
                <IconButton size={2.5} icon={<LinkedInIcon/>}
                            linkLabel={'LinkedIn account of Steven Toben to connect and learn more about the software developer'}
                            href={'https://www.linkedin.com/in/steven-toben-728483180'}/>
              </div>
              <div className={'Group-email-and-phone'}>
                <div>{`steventoben@gmail.com`}</div>
                <div>{`+1 (636) 385-4630`}</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{height: '4rem'}}/>
        <WorkTimeline/>

        <div style={{height: '4rem'}}/>
        <section className={'Section-skills'}>
          <h2 style={{marginBottom: '2rem', alignSelf: 'flex-start'}} className={'Type-heading small'}>Skills</h2>
          <div className={'Featured-skills-card'}>
            <h3 style={{alignSelf: "flex-start", padding: '1rem', fontSize: '1.5rem'}} className={'Type-subtitle medium'}>{`Featured skills`}</h3>
            <p style={{alignSelf: "flex-start", marginBottom: '1rem', padding: '0 1rem'}} className={'Type-body small'}>{`These skills are my specialty, and I have `}<strong>{`at least 4 years of experience`}</strong>{` with the following technologies.`}</p>
            <ViewportObserver triggerOnce onTrigger={() => trackContentLoaded('Technology cards')}>
              <div ref={opacityRef} /*style={{opacity: scrollPosition.current / window.innerHeight}}*/ className={'Skills-group'}>
                <TechnologyCard technology={{name: 'React', logo: <ReactLogo/>, brief: 'Develop complex front-end apps', yearsOfExperience: 4}} />
                <TechnologyCard technology={{name: 'TypeScript', logo: <TypeScriptLogo/>, brief: 'Write type-safe front-end and back-end apps', yearsOfExperience: 4}} />
                <TechnologyCard technology={{name: 'NodeJS', logo: <NodeJsLogo/>, brief: 'Creating various JavaScript apps that run outside of browsers', yearsOfExperience: 4}} />
                <TechnologyCard technology={{name: 'Java', logo: <JavaLogo/>, brief: 'Create microservices, server-side apps, and desktop apps', yearsOfExperience: 4}} />
                <TechnologyCard technology={{name: 'Spring', logo: <SpringFrameworkLogo/>, brief: 'Create secure web apps and APIs with data store access', yearsOfExperience: 4}} />
                <TechnologyCard technology={{name: 'PostgreSQL', logo: <PostgresLogo/>, brief: 'Persistent data store accessed with various ORMs', yearsOfExperience: 4}} />
                {/*<TechnologyCard technology={{name: 'AWS', logo: <AwsLogo/>, brief: '', yearsOfExperience: 4}} />*/}

              </div>
            </ViewportObserver>
          </div>
          <div className={'Skills-g2'}>
            <div>
              <h3 className={'Type-title large'}>{`Familiar software engineering concepts`}</h3>
            </div>
            <div className={'Skills-g2-subgrid'}>
              <div >
                <h6 style={{fontWeight: 700, marginBottom: '0.25rem'}} className={'Type-title small'}>API Development</h6>
                <p className={'Type-body small'}>I am very familiar with the design, development, testing, and deployment of APIs, specifically REST APIs.</p>
              </div>
              <div>
                <h6 style={{fontWeight: 700, marginBottom: '0.25rem'}} className={'Type-title small'}>Microservices</h6>
                <p className={'Type-body small'}>I have experience creating microservices and deploying them in a containerized environment.</p>
              </div>
              <div>
                <h6 style={{fontWeight: 700, marginBottom: '0.25rem'}} className={'Type-title small'}>Agile Methodology</h6>
                <p className={'Type-body small'}>I have practiced the Agile methodology at previous workplaces. I'm familiar with scrum, retrospectives, sprints, code feedback and testing, Kanban boards, etc.</p>
              </div>
              <div>
                <h6 style={{fontWeight: 700, marginBottom: '0.25rem'}} className={'Type-title small'}>Automation and Testing</h6>
                <p className={'Type-body small'}>I have experience creating a variety of types of tests in various languages. I've created unit tests, snapshot tests, and integration tests with tools like Jest, JUnit, Mockito, Mocha, etc. I've also created workflow and full e2e tests using Selenium.</p>
              </div>
            </div>
          </div>
        </section>

        {/*<div>
          <ProjectCardFull project={projectSample1}/>
          <ProjectCard project={projectSample1}/>
        </div>*/}
        {/*<section>
          <ProjectCardContainer project={projectSample1}/>
        </section>*/}

        <div style={{height: '4rem'}}/>
        <section className={'Section-featured-projects'}>
          <h2 className={'Type-heading small'}>{`Featured Projects`}</h2>

          {/*<ViewportObserver onTrigger={() => setIsInitiated({...isInitiated, socialApp: true})}>*/}
            <FeaturedProjectCard project={featuredProject1} />
          {/*</ViewportObserver>*/}

          <div style={{height: '4rem'}}/>

          {/*<ViewportObserver onTrigger={() => setIsInitiated({...isInitiated, bookingApp: true})}>*/}
            <FeaturedProjectCard project={featuredProject2} />
          {/*</ViewportObserver>*/}

          {/*<div style={{height: '4rem'}}/>*/}
          {/*<div className={'Featured-project-preview'}
               onClick={() => openModal(<Modal open={isModalActive} closeModal={closeModal}>
                 <FeaturedProjectLightbox closeModal={closeModal} classNames={'lightbox'} project={featuredProject1}/>
               </Modal>, scrollPosition.current)}>
            <h2 className={'Type-subtitle large'}>{featuredProject1.name}</h2>

            <div className={'Featured-project-preview-logos'}>{featuredProject1.techLogos?.map(logo => {
              return logo;
            })}</div>
            <div className={'View-more-cta'}>View more</div>
          </div>
          <div className={'Featured-project-preview'}
               onClick={() => openModal(<Modal open={isModalActive} closeModal={closeModal}>
                 <FeaturedProjectLightbox closeModal={closeModal} classNames={'lightbox'} project={featuredProject2}/>
               </Modal>, scrollPosition.current)}>
            <h2 className={'Type-subtitle large'}>{featuredProject2.name}</h2>
            <div className={'Featured-project-preview-logos'}>{featuredProject2.techLogos?.map(logo => {
              return logo;
            })}</div>
            <div className={'View-more-cta'}>View more</div>
          </div>*/}
        </section>
        {/*<button onClick={() => openModal(<Modal open={isModalActive} closeModal={closeModal}>
          <FeaturedProjectLightbox classNames={'lightbox'} project={featuredProject1}/>
        </Modal>, scrollPosition.current)}>Open</button>*/}
        {/*<section>
          <FeaturedProject project={projectItemSample}/>
        </section>*/}
       {/* <section>
          <div className={'ProjectCard-grid'}>
            <ProjectCard project={musicPlayerProject} />
            <ProjectCard project={timeBookingProject} />
            <ProjectCard project={privateNoteProject} />
            <ProjectCard project={musicPlayerProject} />
          </div>
        </section>*/}
        <ProjectsTimeline/>

        <div style={{height: '4rem'}}/>
        {/*<Modal open={isOpen} closeModal={closeModal} >
        <div className={'Get-in-touch-container'}>
          <h4 className={'Type-title large title'}>{`Get in Touch`}</h4>
          <div className={'Social-icons'}>
            <IconButton size={2.5} icon={<GithubIcon/>} linkLabel={'Github account of Steven Toben containing many software engineering projects'} href={'https://github.com/steventoben'}/>
            <IconButton size={2.5} icon={<LinkedInIcon/>} linkLabel={'LinkedIn account of Steven Toben to connect and learn more about the software developer'} href={'https://www.linkedin.com/in/steven-toben-728483180'}/>
          </div>
          <div className={'Group-email-and-phone'}>
            <div>{`steventoben@gmail.com`}</div>
            <div>{`+1 (636) 385-4630`}</div>
          </div>
        </div>
        </Modal>*/}
      </div>
  );
}

export default App;
