import React, {ReactNode} from "react";
import {IconButton} from "../Button/IconButton";
import {GithubIcon, LinkedInIcon} from "../Icons/Icon";

interface Props {
    children?: ReactNode;
}
export function Header(props: Props) {
    return(
        <header className={'App-header'}>
            <span className={'Type-display small name-logo'}>{`Steven Toben`}</span>
            <div className={'Social-icons'}>
                <IconButton size={2} icon={<GithubIcon/>} linkLabel={'Github account of Steven Toben containing many software engineering projects'} href={'https://github.com/steventoben'}/>
                <IconButton size={2} icon={<LinkedInIcon/>} linkLabel={'LinkedIn account of Steven Toben to connect and learn more about the software developer'} href={'https://www.linkedin.com/in/steven-toben-728483180'}/>
            </div>
        </header>
    );
}
