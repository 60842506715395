import {AngelCircleThin, AngelThin, ArrowCircleThin, Chevron, ReactLogo} from "../Icons/Icon";
import React, {ReactNode, SetStateAction} from "react";
import {ProgressIndicator} from "./ProgressIndicator";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';

export interface ProjectData {
    name: string;
    briefDescriptions: Array<string>;
    featureDescriptions: Array<string>;
    linkToRepo?: string;
    tags?: Array<string>;
    mediaUrls?: Array<string>;
}

export const projectSample1: ProjectData = {
    name: 'React Social Media App',
    briefDescriptions: [
        'Used ReactJS to create a social media front-end app',
    ],
    featureDescriptions: [
        'Used ReactJS to create a social media front-end app',
        'Created hundreds of React components and hooks using JSX, TypeScript, and Styled Components',
        'Implemented client-side routing with ReactRouter and created custom guarded routes requiring a valid authentication context'
    ],
    linkToRepo: 'github.com/steventoben',
    tags: ['React', 'NextJS', 'TypeScript'],
    mediaUrls: ['','','','','']
}

interface ProjectCardProps {
    project: ProjectData;
    isExpanded?: boolean;
    setIsExpanded?: React.Dispatch<SetStateAction<boolean>>;
}
/*
export function ProjectCard(props: ProjectCardProps) {
    const {
        project,
        isExpanded = false,
        setIsExpanded
    } = props;

    const [expanded, setExpanded] = React.useState(false);

    function handleExpand() {
        setIsExpanded?.((v)=>!v);
    }

    return(
        <div className={`ProjectCard-container ${isExpanded?'expanded':''}`}>
            <ImageGallery srcList={project.mediaUrls || ['']}/>
            <div className={'ProjectCard-content'}>
                <a href={project.linkToRepo}><h3 style={{fontWeight: 700}} className={'Type-title small'}>{project.name}</h3>{/!*github logo link*!/}</a>
                <div className={'ProjectCard-body'}>
                    {project.briefDescriptions.map((line) => {
                        return(
                            <p className={'Type-body small'} key={line}>{line}</p>
                        );
                    })}
                </div>
            </div>
            <div className={'ProjectCard-tags'}>
                {project.tags?.map((tag) => {
                    return(
                        <span className={'ProjectCard-tag'} key={tag}>{tag}</span>
                    );
                })}
            </div>
            <button onClick={() => handleExpand()}>More</button>
        </div>
    );
}
*/

interface GalleryProps {
    srcList: string[];
    interval?: number;
    activeIndex: number;
    setActiveIndex: React.Dispatch<SetStateAction<number>>;
}
export function ImageGallery(props: GalleryProps) {
    const {
        srcList = [''],
        interval = 4000,
        activeIndex,
        setActiveIndex
    } = props;
    const imageCount = srcList.length;
    let id: number | undefined;
    //const [activeIndex, setActiveIndex] = React.useState<number>(0);
    const stepIndexForward = React.useCallback(() => {
        console.log('forward');
        //window.clearInterval(id);
        setActiveIndex((prevState: number) => {
            if(prevState === srcList.length-1) {
                return 0;
            }
            return (prevState + 1);
        });
    }, [srcList.length]);
    const stepIndexBackward = React.useCallback(() => {
        console.log('backward')
        //window.clearInterval(id);
        setActiveIndex((prevState: number) => {
            if(prevState === 0) {
                return srcList.length-1;
            }
            return (prevState - 1);
        });
    }, []);

    React.useEffect(() => {
        id = window.setInterval(() => {
            stepIndexForward();
        }, interval);
        return () => window.clearInterval(id);
    }, [activeIndex]);

    return(
        <div className={'Gallery'}>
            <div className={'Gallery-window'} style={{transform: `translateX(-${100 * activeIndex}%)`}}>
                {srcList.map((image) => {
                    return(
                        <GalleryItem>
                            <img src={image} alt={''} key={image}/>
                            <span onClick={() => stepIndexBackward()} className={'Gallery-item-trigger--left'}>
                                {/*<span className={'Icon-large-left'}><AngelThin className={'rotate-left'}/></span>*/}
                            </span>
                            <span onClick={() => stepIndexForward()} className={'Gallery-item-trigger--right'}>
                                {/*<span className={'Icon-large-right'}><AngelThin className={'rotate-right'}/></span>*/}
                            </span>
                        </GalleryItem>
                    );
                })}
            {/*{srcList.map((image) => {
                return(
                    <div style={{backgroundColor: color.current}} className={'Gallery-item'}>
                        <img src={image} alt={''} key={image}/>
                        <span onClick={() => stepIndexBackward()} className={'Gallery-item-trigger--left'}/>
                        <span onClick={() => stepIndexForward()} className={'Gallery-item-trigger--right'}/>
                    </div>
                );
            })}*/}
            </div>
            <ProgressIndicator setActiveIndex={setActiveIndex} totalCount={imageCount} currentProgress={activeIndex}/>
        </div>
    );
}
function GalleryItem(props: {children: ReactNode}) {
    const {children} = props;
    //const color = React.useRef(`#${Math.random().toString(16).substring(2, 8)}`);

    return(
        <div /*style={{backgroundColor: color.current}}*/ className={'Gallery-item'}>
            {children}
        </div>
    );
}

interface Props {
    srcList: string[]
}
export function Gallery(props: Props) {
    return(
        <Swiper>
            {props.srcList.map(src => (
                <SwiperSlide key={src}>{<img src={src}/>}</SwiperSlide>
            ))}
        </Swiper>
    );
}