//import {makeClassNames} from "../../util/classn";
import {ReactElement, ReactNode} from "react";

interface CardProps {
    children?: ReactNode;
}
export function Card(props: CardProps) {
    const {
        children
    } = props;
    return(
        <article className={'Card-container'}>
            {children}
        </article>
    );
}
interface Technology {
    name: string;
    logo: ReactNode;//ReactNode;//string | ReactNode;
    brief: string;
    yearsOfExperience: number;
}
interface TechCardProps {
    children?: ReactNode;
    technology: Technology;
}
export function TechnologyCard(props: TechCardProps) {
    const {
        children,
        technology
    } = props;
    return(
        <article className={'Card-container Technology-card Preview-card'}>
            <span>
                {/*<img src={technology.logo} alt={technology.name}/>*/}
                {technology.logo}
            </span>
            <div>
                <h4 style={{marginBottom: '0.25rem'}} className={'Type-title medium'}>{technology.name}</h4>
                <span style={{lineHeight: 1}} className={'Type-body small'}>{technology.brief}</span>
            </div>
        </article>
    );
}

