import React from "react";

/*export function useUpdateEffect(callbackFn: React.EffectCallback, deps: any[]) {
    const initRender = React.useRef(true);
    React.useEffect(() => {
        if(!initRender.current) {
            callbackFn();
        }
        initRender.current = false;
    }, [initRender.current, ...deps]);
}*/
export function useUpdateEffect(effectCallback: () => void, deps: any[]) {
    const isInitialRender = React.useRef(true);
    React.useEffect(() => {
        if(!isInitialRender.current) {
            return effectCallback();
        } else {
            isInitialRender.current = false;
        }
    }, deps);
}
//useUpdateEffect(() => {}, []);
