import React from "react";
import {formatDate} from "../../util/dateUtils";
import {FadeInText, SvgIconReact, ViewportObserver, WorkItem} from "./VerticalTimeline";
import {APP} from "../../util/constants";
import {
    AwsLogo,
    ElmLogo,
    JavaScriptLogo,
    NestLogo, NextJsLogo,
    NodeJsLogo,
    PostgresLogo,
    ReactLogo,
    SqlServerIcon,
    TypeScriptLogo
} from "../Icons/Icon";
import {useTracking} from "../../hooks/useTracking";

interface WorkTimelineItemProps {
    //id for identifying a specific timeline item
    id?: string;
    //id of the timeline that contains this item.
    groupId?: string;
    //insertion position of item in the timeline group
    index?: number;

    item: WorkItem;
}
export function WorkTimelineItem(props: WorkTimelineItemProps) {
    const {
        id,
        groupId,
        index = -1,
        item
    } = props;
    const [isVisible, setIsVisible] = React.useState(false);
    const coloring = item.coloring || '#17eaec';
    const {trackContentLoaded} = useTracking();
    React.useEffect(() => {
        if (isVisible) {
            trackContentLoaded(item.name);
        }
    }, [isVisible]);
    return(
        <ViewportObserver onTrigger={() => setIsVisible(true)}>
            <div data-visible={isVisible} className={`Timeline-entry ${(index+2)%2===0?'':'reverse'}`}>
            <span style={{backgroundColor: coloring}} className={'Timeline-item_icon Timeline_marker'}>
                {item.markerLogo || <SvgIconReact/>}
            </span>
                <div data-visible={isVisible} className={'Timeline-item Card'}>
                    {/*<span className={`Timeline-item_arrow ${(index+2)%2===0?'':'reverse'}`}/>*/}
                    <span/>
                    <div style={{borderTopColor: coloring}} className={`Timeline-item_content ${encodeURIComponent(item.name)} ${(index+2)%2===0?'':'reverse'}`}>
                        <header id={item.name.toLowerCase().replaceAll(' ', '_')} className={'WorkTimelineItem-header'}>
                            <h2 className={'Type-title medium'}>{item.name}</h2>
                            <span>{item.position}</span>
                        </header>
                        <div className={'Fade-effect'}/>
                        <p className={'Type-body small'}>{item.description}</p>
                        <ul className={'WorkTimelineItem-list'}>
                            {item.descriptions && item.descriptions.map((listItem) => {
                                return(
                                    <li className={'WorkTimelineItem-list-item'}>
                                        <span className={'Type-body small'}>{listItem}</span>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className={`Timeline-item_date Timeline_work OneColumn`}>
                        <span className={'Type-label small'}>
                            {formatDate(new Date(item.dateRange.start))} - {item.dateRange.finish==='current' ? 'current' : formatDate(new Date(item.dateRange.finish))}
                        </span>
                        </div>
                        <div className={'WorkTimelineItem-logos'} style={{marginTop: '0rem'}}>{item.techLogos && item.techLogos.map((logo) => {
                            return logo;
                        })}</div>
                    </div>
                    <div className={`Timeline-item_date TwoColumn ${(index+2)%2===0?'':'reverse'}`}>
                        <FadeInText>
                        <span className={'Type-label large'}>
                            {formatDate(new Date(item.dateRange.start))} - {item.dateRange.finish==='current' ? 'current' : formatDate(new Date(item.dateRange.finish))}
                        </span>
                        </FadeInText>
                    </div>
                </div>
            </div>
        </ViewportObserver>
    );
}

function DriveLogo() {
    return(
        <span className={'fill-size'}>
            <img className={'fill-size'} alt={'Drive Social Media logo'} src={`${APP.CDN}/d.jpg`}/>
        </span>
    );
}
export const workItemDrive: WorkItem = {
    name: 'Drive Social Media',
    position: 'Software Engineer',
    dateRange: {
        start: Date.UTC(2023, 7),
        finish: Date.UTC(2024, 2)
    },
    description: '',
    tags: ['JavaScript', 'SQL Server', 'IIS', 'Azure'],
    //coloring: '#d56e3b',
    coloring: '#093438',
    markerLogo: <DriveLogo/>,
    techLogos: [<NextJsLogo/>, <ReactLogo/>, <TypeScriptLogo/>, <NodeJsLogo/>, <NestLogo/>, <PostgresLogo/>, <AwsLogo/>],
    descriptions: [
        'Created React components and pages for significant new features to the app',
        'Designed new API endpoints, wrote backend services and controllers to route traffic to Kafka topics',
        `Bootstrapped the company knowledge-base, programming the infrastructure with AWS CDK`,
        'Re-engineered the authorization system using Auth0',
        'Setup new GoodData environments in isolated domains using CloudFlare',
        `Worked as a support developer, answering to clients' issues and helping them resolve their problems`
    ]
};

function EngageLogo() {
    return(
        <span className={'fill-size'}>
            <img className={'fill-size'} alt={'Engage Software logo'} src={`${APP.CDN}/e.png`}/>
        </span>
    );
}
export const workItemEngage: WorkItem = {
    name: 'Engage',
    position: 'Software Developer',
    dateRange: {
        start: Date.UTC(2023, 3),
        finish: Date.UTC(2023, 7)
    },
    description: '',
    tags: ['C#', 'Elm', 'SQL Server', 'IIS', 'Azure'],
    //coloring: '#d56e3b',
    coloring: '#f47a23',
    markerLogo: <EngageLogo/>,
    techLogos: [<ElmLogo/>, <SqlServerIcon/>],
    descriptions: [
        'Developed state-driven front-ends using the purely functional programming language Elm',
        'Created REST APIs with C# and DotNetNuke CMS',
        `Styled front-end apps to meet client's needs with LESS`,
        'Wrote SQL in SQL Server 2020',
        'Hosted websites on IIS'
    ]
};

function HuronLogo() {
    return(
        <span className={'fill-size'}>
            <img className={'fill-size'} alt={'Huron Consulting logo'} src={`${APP.CDN}/h.jpeg`}/>
        </span>
    );
}
const workItemHuron: WorkItem = {
    name: 'Huron',
    position: 'Technical Consultant',
    dateRange: {
        start: Date.UTC(2022, 5),
        finish: Date.UTC(2022, 11)
    },
    description: '',
    tags: ['JavaScript', 'SQL Server', 'IIS', 'Azure'],
    //coloring: '#d56e3b',
    coloring: '#02548c',
    markerLogo: <HuronLogo/>,
    techLogos: [<JavaScriptLogo/>, <SqlServerIcon/>],
    descriptions: [
        'Communicated directly with clients to assess business requirements',
        'Worked on multiple teams, balancing competing deadlines of projects for different clients',
        //'Created a service with the Java AWS SDK to scan and query DynamoDB tables and transform the response streams',
        'Created, extended, and upgraded web dashboards for top research institutes using JavaScript, HTML, and CSS',
        //'Used JUnit and Mockito to unit test the REST API controllers and to run integration tests',
        'Documented projects thoroughly by using version control and writing supplemental documentation with unit tests.',
        'Extended dashboards by creating patches and applying them to a multi-stage dev/stage/prod environment and deploying them on Microsoft IIS'
    ]
};
