import React from "react";

export function useScrollLock() {
    const offsetRef = React.useRef<number>(0);
    const lockScrolling = React.useCallback((offset: number = 0) => {
        offsetRef.current = offset || document.scrollingElement!.scrollTop;
        (document.querySelector('.App') as HTMLElement).style.overflow = 'hidden';
        (document.querySelector('.App') as HTMLElement).style.position = 'fixed';
        //document.getElementById('main')!.style.overflow = 'hidden';
        //document.getElementById('main')!.style.position = 'fixed';
        console.log(offset);
        console.log(offsetRef);
        (document.querySelector('.App') as HTMLElement).style.top=`-${offset}px`;//.scrollTo(0, -offset);//.style.position = 'fixed';
        //document.scrollingElement!.scrollTo(0, offset);
    }, []);
    const unlockScrolling = React.useCallback((offset: number = 0) => {
        (document.querySelector('.App') as HTMLElement).style.overflow = 'auto';
        (document.querySelector('.App') as HTMLElement).style.position = 'static';
        //document.getElementById('main')!.style.overflow = 'auto';
        //document.getElementById('main')!.style.position = 'static';
        document.scrollingElement!.scrollTo(0, offset);
    }, []);
    return {lockScrolling, unlockScrolling}
}
